/* ------------------------------------------------------------------------- */


/* FOOTER STYLES */


/* ------------------------------------------------------------------------- */

.footer { border-top:4px solid $color-bg; }
.footer-top { display:flex; }
.footer-left { flex-basis:60%; padding:50px 5%; display: flex; justify-content: space-between;}
.footer-left .footer-logo-container { padding-right:20px; }
.footer-left .web-logo .footer_logo { display:block; max-width:140px; margin-bottom: 30px; }
.footer-left .footer_social { }
.footer-left .footer_social ul { display: flex; align-items: center;}
.footer-left .footer_social ul li {display:inline-block; margin:0 10px 0 0;}
.footer-left .footer_social ul li a { position:relative; overflow:hidden; display:block; }
.footer-left .footer-box { padding-right:20px; }
.footer-left .footer-box h3 { margin-bottom:0; }
.footer-left .footer-box hr { margin:20px 0; }
.footer-left .footer-box ul.contact li { margin-bottom:15px; }
.footer-left .footer-box ul.contact li a { display:flex; align-items: flex-start; color:$color-txt; }
.footer-left .footer-box ul.contact li a img { margin-right:10px; min-width:25px; }
.footer-left .footer-box ul.contact li a span { display:inline-block; margin-top:5px; }
.footer-left .footer-box p { margin-bottom:15px; }

.footer-map { flex-basis:40%; background:white url(../images/map.jpg) no-repeat; background-size: cover; background-position: right center; }
.footer-map a { display: block; width: 100%; height: 100%;}

.footer-bottom { padding:10px 5%; background-color:$color; display:flex; justify-content:space-between; color:white; }
.footer-bottom p { margin:0; color:white; }
.footer-bottom p a, .footer-bottom a { color:white; display: inline-block; margin: 0 5px;}



@media only screen and (max-width: 1100px){
	.footer-left { padding: 50px 2% 50px 5%;}
	.footer-left .web-logo .footer_logo { max-width: 120px;}
	.footer-left .footer-box p,
	.footer-left .footer-box ul.contact li { font-size:13px; }
}
@media only screen and (max-width: 1000px){
	.footer-left { flex-basis:70%; padding: 30px 2% 30px 5%;}
	.footer-map { flex-basis:30%; }
	.footer-bottom p,
	.footer-left .footer-box p,
	.footer-left .footer-box ul.contact li { font-size:12px; }

}
@media only screen and (max-width: 800px){
	.footer-top { flex-direction: column;}
	.footer-map {height: 200px; flex-basis: auto;}
	.footer-left .footer-box ul.contact li a img { margin-right: 7px; min-width: 20px; width: 20px;}
	.footer-left .footer-box ul.contact li a span { margin-top:0; }
}
@media only screen and (max-width: 600px){
	.footer-bottom { flex-direction: column; text-align: center;}
	.footer-bottom-left { margin-bottom:10px; }
	.footer-bottom p a, .footer-bottom a { margin: 0 5px 5px;}
}
@media only screen and (max-width: 480px){
	.footer-left {flex-wrap:wrap; padding:30px 5%;}
	.footer-left .footer-logo-container { padding-right: 0; width: 100%; margin-bottom: 20px;}
	.footer-left .web-logo .footer_logo { margin-bottom:20px; }
	.footer-left .footer-box { padding-right: 0; flex-basis: 45%;}
	.footer-bottom p,
	.footer-left .footer-box p,
	.footer-left .footer-box ul.contact li { font-size:11px; }
}


