/* ------------------------------------------------------------------------- */


/* PAGE PAGE STYLES */


/* ------------------------------------------------------------------------- */


/* MAIN */
main{ padding-top: 90px;}
.main_content{ position: relative;left: 0;min-height: 870px !important;padding: 20px 40px 40px 30px;background: transparent;margin: 0px 0px 0px 230px;}
.section {padding: 30px 0;border-top: 1px solid white;}

/* MAIN TITLE BOX */
.header-box { display:flex; align-items: center; justify-content: space-between; margin-bottom:30px; }
.header-box .header-left { display:flex; align-items: center; justify-content: flex-start; }
.header-box .header-left h1 { margin:0 20px 0 0; line-height: 110%;}
.header-box .header-right {}

/* FILTER BOX */
.filter-box { display:flex; /*align-items: center;*/ justify-content: space-between; padding:12px; background:white; border-radius:8px; margin-bottom:30px; }
.filter-box.big-filters { flex-direction: column-reverse;}
.filter-box .filter-box-left { flex: 1; display:flex; align-items: center; flex-wrap:wrap; }
.filter-box.no-filters .filter-box-left { display: none;}
.filter-box .filter-box-right { display:flex; /*align-items: center;*/ justify-content: flex-end; padding-left:10px; }
.filter-box.big-filters .filter-box-right { padding-left:0; }
.filter-box.big-filters .filter-box-right .filter-search { flex:1; }
.filter-box.no-filters .filter-box-right { padding-left:0; width: 100%; }
.filter-box.no-filters .filter-box-right .filter-search { flex:1; }
.filter-box .filter-box-right .filter-search input[type=search] { width:100%; }
.filter-box .filter-date-container { margin-left: 20px; text-align:right;}
.filter-box .filter-box-filter .filter-group button { padding: 8px 12px; border-radius: $border-radius;  font-size: 16px; line-height: $line-height2; display: inline-block; margin:2px 5px 3px 0; background-color: #f5f5f5; cursor: pointer;}
.filter-box .filter-box-filter .filter-group button.active {background-color: #CCDBD6;}

/* LICITACIONS FILTER */

.tenders-filter-container .tenders-main-filter { display:flex; justify-content: space-between; margin-bottom:30px; }
.tenders-filter-container .tenders-main-filter .tenders-main-filter__item { flex-basis: 31%; height:60px; display:flex; align-items:center; justify-content: space-between; padding:0 20px; background:white; border-radius:$border-radius; cursor:pointer; }
.tenders-filter-container .tenders-main-filter .tenders-main-filter__item.selected { background:$color-selected;}
.tenders-filter-container .tenders-main-filter .tenders-main-filter__item .bt-title { display: flex; align-items: center; font-weight:$font-weight-bold; font-size:14px; line-height:110%; }
.tenders-filter-container .tenders-main-filter .tenders-main-filter__item .bt-title span.notification { width:40px; height:40px; display:flex; align-items:center; justify-content: center; font-size:15px; font-weight:$font-weight-bold; line-height:110%; color:white; background:$color-notification; border-radius:100px; margin-right:12px; }

.tenders-filter-container .tenders-box-filter { background:white; border-radius:$border-radius; margin-bottom: 20px;}
.tenders-filter-container .tenders-box-filter .tenders-filter-type { display:flex; padding:15px; border-bottom:1px solid #f0f0f0; }
.tenders-filter-container .tenders-box-filter .tenders-filter-type p { margin: 0 20px 0 0; padding-top: 8px; line-height: 110%; flex: 1;}
.tenders-filter-container .tenders-box-filter .tenders-filter-type .tenders-filter-type__buttons {}
.tenders-filter-container .tenders-box-filter .tenders-filter-type .btn { font-size:14px; font-weight:$font-weight-bold; line-height:110%; padding:10px 15px; margin: 0 0 5px 5px; border-radius:$border-radius; background:#f5f5f5;}
.tenders-filter-container .tenders-box-filter .tenders-filter-type .btn.selected { background:$color-selected; }
.tenders-filter-container .tenders-box-filter .tenders-filter-search-date { display:flex; align-items:center; justify-content: space-between; padding: 10px 15px; margin-bottom:5px;}
.tenders-filter-container .tenders-box-filter .tenders-filter-search-date .filter-search { flex: 1; margin-right: 20px;}
.tenders-filter-container .tenders-box-filter .tenders-filter-full { display:none; }
.tenders-filter-container .tenders-box-filter .tenders-filter-full.selected { display:block; }
.tenders-filter-container .tenders-box-filter .tenders-filter-groups { display: flex; padding: 10px 15px; }
.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group { flex-basis: 25%; padding: 0 10px; border-right: 1px dashed #cbcbcb; }
.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group:first-child { padding-left:0;}
.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group:last-child { padding-right:0; border-right:none; }
.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group .radiobutton__group .background { background-color:#f0f0f0; padding:10px; border-radius:$border-radius; }
.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group .radiobutton__group .background.selected { background:$color-selected; }
.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group .radiobutton__group p { font-weight:$font-weight-bold; line-height:110%; margin-bottom:5px;}
.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group select { margin-bottom:10px; }
.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group select.selected { background:$color-selected; }
.filter-search input[type=search]{ width: 100%; background: #f5f5f5;}
.tenders-filter-full__group p { font-weight:$font-weight-bold; line-height:110%; margin-bottom:15px;}
.tenders-filter-full__group input[type=text]{ width: 100%;}

.tenders-filter-container .tenders-buttons-filter { display:flex; justify-content: space-between; }
.tenders-filter-container .tenders-buttons-filter .btn_big { flex-basis:49%; border:1px solid $color; background:transparent; border-radius:$border-radius; text-align:center; padding:10px 15px 7px; font-size:16px; font-weight:$font-weight-bold; line-height: 110%; }
.tenders-filter-container .tenders-buttons-filter .btn_big img { margin-right:5px; display: inline-block;}
.tenders-filter-container .tenders-buttons-filter .btn_big.btn_main { background:$color; color:white; }

.tenders-filter-container .tenders-buttons-download { display:flex; justify-content: end; margin-top: 20px }
.tenders-filter-container .tenders-buttons-download a { margin: 0;}

.tenders-buttons-filter_large{ display: block!important;}
.tenders-buttons-filter_large .btn_big{width: 100%}

.tenders-buttons-filter_applied { display:flex; margin-top: 20px; align-items:center; }
.tenders-buttons-order_applied { display:flex; margin-top: 20px; align-items:center; }
.filter_applied{padding: 5px 10px; background: #CCDBD6; border-radius: 5px; margin-right: 10px}
.filter_applied p{padding: 0; margin: 0}
.filter_applied p span{font-weight: $font-weight-bold}

.tenders-table-full { display:none; margin-top: 20px; overflow: hidden;}
.tenders-table-full.selected { display:block; }

.row-filter{display: flex;background: white; padding: 5px 15px;border-radius: 5px;margin-right: 20px;width: 200px;}
.row-filter label{margin-top: 5px; width: 70%;}
.row-filter select{width: 30%;}

.row-filter:last-child{;width: 240px;}
.row-filter:last-child label{margin-top: 5px; width: 25%;}
.row-filter:last-child select{width: 75%;}

.flex-fill label .label-wrapper { margin-right: 22px; z-index: 1;  position: relative; width: 100%; }
.flex-fill label .label-wrapper .label { font-size:14px; color: black; line-height: 100%; margin-bottom: 5px; font-weight:$font-weight-normal; }
.flex-fill:last-child label .label-wrapper { margin: 0; }
.price-vals span{ font-family: $main}
.range-slider {width: 300px;margin: auto;text-align: center;position: relative;height: 6em;}
.range-slider svg, .range-slider input[type=range] {position: absolute;left: 0;bottom: 0;}
.tenders-filter-full__group p { margin-bottom: 10px}
.flex-fill{margin-bottom: 10px}

.notification-tender{padding: 3px 8px;}

/* LICITACIONS SINGLE */

.tenders-title-box { background:white; border-radius:10px; padding:20px; margin-bottom:30px; }
.tenders-title-box .tenders-title-box__top { display:flex; align-items: center; justify-content: space-between; margin-bottom:20px; }
.tenders-title-box .tenders-title-box__top span { display:inline-block; background:#f0f0f0; padding:4px 10px; border-radius:5px; font-size:14px; line-height:110%; margin-right:5px; }
.tenders-title-box .tenders-title-box__top .tenders-title-box__top-right { text-align: right;}
.tenders-title-box .tenders-title-box__top .tenders-title-box__top-right .tender-status { display:flex; align-items: center; font-size:14px; line-height:110%;}
.tenders-title-box .tenders-title-box__top .tenders-title-box__top-right .tender-status.saved { color:#00C97D;}
.tenders-title-box .tenders-title-box__top .tenders-title-box__top-right .tender-status img { margin-right:7px;}
.tenders-title-box .tenders-title-box__title { margin:0;}

.updates-section { margin-bottom:30px;}
.updates-section .updates-title { color:#B51700; margin-bottom:20px; }
.updates-box { background:#FFE1DD; border-radius:10px; padding:20px 20px 10px; margin-bottom:10px; }
.updates-section .updates-box .updates-box__top { margin-bottom:15px; }
.updates-section .updates-box .updates-box__top span { display:inline-block; background:#B51700; color:white; padding:4px 10px; border-radius:5px; font-size:14px; line-height:110%; margin-right:5px; }
.updates-date{ display:inline-block; background:#B51700; color:white; padding:4px 10px; border-radius:5px; font-size:14px; line-height:110%; margin-right:5px; }
.updates-section .updates-box p { margin-bottom:10px;}
.updates-section .updates-bottom { text-align: right;}
.updates-section .updates-bottom a.updates-more { color:#B51700; padding:3px 1px; border-bottom:1px solid #B51700; font-size:15px; line-height:110%;  }

.title-section-separation { margin:50px 0 20px; display:flex; align-items:center; justify-content: space-between; }
.title-section-separation .title-section { margin:0 20px 0 0;}
.title-section-separation .title-section hr { background:$color; border:none; width:50px; margin:15px 0; }

.tender-anuncio-box { background:white; border-radius:10px; display:flex; }
.tender-anuncio-box .tender-anuncio-box__column { flex-basis: 33.3%; border-right:1px solid #F0F0F0; padding:20px 12px; }
.tender-anuncio-box .tender-anuncio-box__column:last-child { border:none; }
.tender-anuncio-box .tender-anuncio-box__column .tender-anuncio-block { border-radius:8px; padding:12px;}
.tender-anuncio-box .tender-anuncio-box__column .tender-anuncio-block.highlighted {background:#D9E8E3;}
.tender-anuncio-box .tender-anuncio-box__column .tender-anuncio-block.highlighted-change {background:#FFE1DD;}
.tender-anuncio-box .tender-anuncio-box__column .tender-anuncio-block h2 { font-size:16px; margin-bottom:7px; }
.tender-anuncio-box .tender-anuncio-box__column .tender-anuncio-block p { margin:0; font-size:14px; }

.tenders-obertura-section {}
.tenders-obertura-section .tenders-obertura-section__bottom { margin-top:10px; }
.tenders-obertura-section .tenders-obertura-section__bottom .bt-download { display:inline-flex; align-items:center; background:$color; color:white; padding:7px 15px; border-radius:5px; font-size:15px; line-height:110%; margin-right:5px; }
.tenders-obertura-section .tenders-obertura-section__bottom .bt-download .svg { width: 7px; margin-left:10px; transition: all .8s cubic-bezier(.07,.71,.1,.9); -webkit-transition: all .8s cubic-bezier(.07,.71,.1,.9);}
.tenders-obertura-section .tenders-obertura-section__bottom .bt-download .svg g { fill:white; }
.tenders-obertura-section .tenders-obertura-section__bottom .bt-download:hover .svg { transform:translateY(3px); }

.tenders-adjudicacio-box {background:white; border-radius:10px; padding:20px 20px 10px; margin-bottom:30px;}
.tenders-adjudicacio-box .tenders-adjudicacio-box__top { display:flex; align-items: center; justify-content: space-between; margin-bottom:20px; }
.tenders-adjudicacio-box .tenders-adjudicacio-box__top span { display:inline-block; background:#f0f0f0; padding:4px 10px; border-radius:5px; font-size:14px; line-height:110%; margin-right:5px; }
.tenders-adjudicacio-box .tenders-adjudicacio-box__bottom { display:flex; }
.tenders-adjudicacio-box .tenders-adjudicacio-box__bottom .tender-adjudicacio-block { flex-basis:33.3%; border-right:1px solid #f0f0f0; padding:12px; }
.tenders-adjudicacio-box .tenders-adjudicacio-box__bottom .tender-adjudicacio-block:last-child { border:none; }
.tenders-adjudicacio-box .tenders-adjudicacio-box__bottom .tender-adjudicacio-block h2 { font-size:16px; margin-bottom:7px; }
.tenders-adjudicacio-box .tenders-adjudicacio-box__bottom .tender-adjudicacio-block p { margin:0; font-size:14px; }
.tenders-adjudicacio-box .tenders-adjudicacio-box__bottom .tender-baixes-block { flex-basis:50%; border-right:1px solid #f0f0f0; padding:12px; }
.tenders-adjudicacio-box .tenders-adjudicacio-box__bottom .tender-baixes-block:last-child { border:none; }
.tender-baixes-block h2{ margin-bottom: 15px}
.tender-baixes-block p{ margin-bottom: 0px}

/* COMMISIONS, CIRCULAR, JUNTA SINGLE*/

.info-box { background-color: white; padding: 25px; border-radius: 10px; margin-bottom: 20px;}
.info-box.short { max-width:600px;}
.info-box .tags { margin:0 0 15px; display:flex; flex-wrap:wrap;   }
.info-box .tags span { display:inline-block; text-transform:uppercase; font-size:14px; line-height:$line-height2; border-radius:$border-radius; background-color:$color-bg; color:$color-txt; padding:5px 10px; margin:0 5px 5px 0; }
.info-box .title { margin-bottom:20px; line-height:$line-height; }




@media only screen and (max-width: 1200px){
	.filter-box { flex-direction: column-reverse; margin-bottom: 20px; padding: 5px 12px; }
	.filter-box .filter-box-right { padding-left:0;}
	.filter-box .filter-box-right .filter-search { flex: 1;}

	.tenders-table-full { overflow-x: scroll;}
	.tenders-table-full .table { min-width: 1200px;}
}

@media only screen and (max-width: 1100px){
	main{ padding-top: 60px;}
	.main_content{ margin: 0px 0px 0px 180px; padding: 20px 3% 40px 3%; }
	.header-box { margin-bottom: 15px;}
	.filter-box-filter .filter-group button { padding: 6px 12px; font-size: 13px;}
	.title-section-separation { margin: 30px 0 15px;}
	.title-section-separation .title-section hr { margin: 10px 0;}

	.tenders-filter-container .tenders-box-filter .tenders-filter-type p {padding-top:0;}
	.tenders-filter-container .tenders-box-filter .tenders-filter-type .btn { font-size:13px; padding:10px;}
}
@media only screen and (max-width: 1000px){
	.tenders-filter-container .tenders-main-filter { margin-bottom:20px; }
	.tenders-filter-container .tenders-main-filter .tenders-main-filter__item { height:50px; padding:0 15px; }
	.tenders-filter-container .tenders-main-filter .tenders-main-filter__item .arrow { width:15px;}
	.tenders-filter-container .tenders-main-filter .tenders-main-filter__item .bt-title { font-size:13px; }
	.tenders-filter-container .tenders-main-filter .tenders-main-filter__item .bt-title span.notification { width: 30px; height: 30px; font-size: 13px; margin-right: 10px;}
	.tenders-filter-container .tenders-box-filter .tenders-filter-groups { flex-wrap: wrap; }
	.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group { flex-basis: 33.3%;}
	.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group:nth-child(3) { padding-right: 0; border-right: none;}
	.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group:nth-child(4) { padding-left: 0;}
	.tenders-filter-container .tenders-buttons-filter .btn_big { font-size:15px; }
}
@media only screen and (max-width: 900px){
	.tenders-filter-container .tenders-box-filter .tenders-filter-type { flex-direction: column;}
	.tenders-filter-container .tenders-box-filter .tenders-filter-type p { margin: 0 0 10px;}
	.tenders-filter-container .tenders-box-filter .tenders-filter-type .btn { margin:0 5px 5px 0;}
	.tenders-filter-container .tenders-box-filter .tenders-filter-search-date { flex-direction:column; align-items:flex-end; }
	.tenders-filter-container .tenders-box-filter .tenders-filter-search-date .filter-search { margin-right: 0; margin-bottom: 5px; width: 100%;}
}
@media only screen and (max-width: 800px){
	.main_content { margin: 0;}
	.filter-date-container { margin-left: 0;}
}
@media only screen and (max-width: 600px){
	.header-box { flex-direction: column; align-items: flex-start;}
	.header-box .header-left { margin-bottom: 15px;}
	.header-box .header-right { width: 100%;}
	.info-box { padding: 15px; margin-bottom: 10px;}
	.tenders-filter-container .tenders-main-filter { flex-direction:column; }
	.tenders-filter-container .tenders-main-filter .tenders-main-filter__item {margin-bottom: 5px; min-height: 40px;}
	.tenders-filter-container .tenders-main-filter .tenders-main-filter__item .bt-title span.notification { width: 25px; height:25px; font-size: 11px;}
	.tenders-filter-container .tenders-buttons-filter .btn_big { font-size:14px; }
	.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group { flex-basis: 49%; padding: 0; border: 0; margin-bottom:10px;}
	.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group:nth-child(1),
	.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group:nth-child(3),
	.tenders-filter-container .tenders-box-filter .tenders-filter-groups .tenders-filter-full__group:nth-child(5) { padding-right:10px; margin-right:2%; border-right: 1px dashed #cbcbcb;}
}
@media only screen and (max-width: 480px){
	.filter-box .filter-box-right { flex-direction: column;}
}


