/* ------------------------------------------------------------------------- */


/* MAPS CSS */


/* ------------------------------------------------------------------------- */


/* NEWS ITEM */

.news-item { height:250px; background-color:$color; padding:15px; border-radius: 10px;}
.news-item.category-proyectos { background-color:$color_yellow; }
.news-item.category-socios { background-color:$color_blue; }
.news-item.category-otros-posts { background-color:$color_orange; }
.news-item .news-box {max-height: 100%; overflow: hidden;}
.news-item .news-top { margin:0 0 5px; display:flex; justify-content: space-between; }
.news-item .news-top .tags { display:flex; flex-wrap:wrap; }
.news-item .news-top .tags span { display:inline-block; color:white; text-transform:uppercase; font-size:13px; line-height:$line-height2; border-radius:$border-radius; background-color:rgba(255,255,255,0.2); padding:5px 10px; margin:0 5px 5px 0; }
.news-item .news-top a.link-edit { width:25px; height:25px; border-radius:5px; background:white; display:flex; justify-content: center; align-items: center;}
.news-item .news-top a.link-edit img { width:15px; }
.news-item a.link-news { position:relative; display:inline-block; width:100%; color:white;  }
.news-item a.link-news hr { background:white; margin:20px 0; }
.news-item a.link-news .title { margin-bottom:10px; color:white; font-size:16px; line-height:$line-height; }
.news-item a.link-news:hover .title { text-decoration:underline; }
.news-item a.link-news .subtitle { margin:0; color:white; }
.news-item a.link-news p { margin-bottom:10px; color:white; font-size: 14px; }
.news-item:hover { background-color: #048353; }
.news-item.category-proyectos:hover { background-color:#f5b603; }
.news-item.category-socios:hover { background-color:#02547a; }
.news-item.category-otros-posts:hover { background-color:#ff785d; }


/* AGENDA ITEM */

.agenda-item { height:250px; background-color:white; border-radius: 10px;}
.agenda-item .agenda-item-inner { position:relative; display:flex; flex-direction: column; justify-content: space-between; width:100%; height:100%; color:$color-txt; padding:20px 15px; overflow: hidden; }
.agenda-item .agenda-item-inner .agenda-top {  }
.agenda-item .agenda-item-inner .agenda-top .tags { margin:0 0 8px; display:flex; flex-wrap:wrap;   }
.agenda-item .agenda-item-inner .agenda-top .tags span { display:inline-block; text-transform:uppercase; font-size:12px; line-height:$line-height2; border-radius:$border-radius; background-color:$color-bg; padding:5px 10px; margin:0 5px 5px 0; }
.agenda-item.category-la-cambra .agenda-item-inner .agenda-top .tags span { background-color:$color; color:white; }
.agenda-item.category-proyectos .agenda-item-inner .agenda-top .tags span { background-color:$color_yellow; color:white; }
.agenda-item.category-socios .agenda-item-inner .agenda-top .tags span { background-color:$color_blue; color:white; }
.agenda-item.category-otros .agenda-item-inner .agenda-top .tags span { background-color:$color_orange; color:white; }
.agenda-item .agenda-item-inner .agenda-top .tags span.date { background-color:$color-bg; color:$color-txt; }
.agenda-item .agenda-item-inner .agenda-top .title { margin-bottom:10px; font-size:16px; line-height:$line-height; }
.agenda-item .agenda-item-inner .agenda-top p { margin-bottom:10px; font-size: 14px; }
.agenda-item .agenda-item-inner .agenda-date { display:flex; align-items:center; }
.agenda-item .agenda-item-inner .agenda-date img { margin-right:10px; }
.agenda-item:hover a.agenda-item-inner { background-color: #D7E1DD;}



/* FILE ITEM */

.file-item { height:250px; background-color:white; border-radius: 10px;}
.file-item a { position:relative; display:flex; flex-direction: column; width:100%; height:100%; color:$color-txt; overflow: hidden; padding:15px;}
.file-item a .file-top { display:flex; justify-content: space-between; margin-bottom: 5px; }
.file-item a .file-top .tags { margin:0 0 5px; display:flex; flex-wrap:wrap;   }
.file-item a .file-top .tags span { display:inline-block; text-transform:uppercase; font-size:12px; line-height:$line-height2; border-radius:$border-radius; background-color:$color-bg; padding:5px 10px; margin:0 5px 5px 0; }
.file-item a .file-top .file-arrow { width:25px; height:25px; margin-left:10px; border-radius:5px; border:1px solid $color; display:flex; align-items: center; justify-content: center; }
.file-item a .file-top .file-arrow .svg { width: 7px; transition: all .8s cubic-bezier(.07,.71,.1,.9); -webkit-transition: all .8s cubic-bezier(.07,.71,.1,.9); }
.file-item a:hover .file-top .file-arrow .svg { transform:translateY(4px); }
.file-item a .title { margin-bottom:10px; font-size:16px; line-height:$line-height; }

.file-item.colored { background-color:$color; }
.file-item.colored a { color:white; }
.file-item.colored a .file-top .tags span { background-color:rgba(255,255,255,0.2); }
.file-item.colored a .file-top .file-arrow .svg g { fill:white; }
.file-item.colored a .file-top .file-arrow { border:1px solid white; }
.file-item.colored a .title { color:white; }


/* PROJECT ITEM */

.project-item { height:250px; background-color:$color; background-size:cover; background-position:center; padding:15px; border-radius: 10px; overflow:hidden; }
.project-item:before { content: ""; position: absolute; width: 100%; height: 100%; bottom: 0; left: 0; background: linear-gradient(180deg, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.5) 90%);}
.project-item .project-box {height: 100%; display:flex; flex-direction:column; justify-content: space-between;}
.project-item .project-top { margin:0 0 5px; display:flex; justify-content: space-between; z-index: 1;}
.project-item .project-top .tags { display:flex; flex-wrap:wrap; }
.project-item .project-top .tags span { display:inline-block; color:white; text-transform:uppercase; font-size:13px; line-height:$line-height2; border-radius:$border-radius; background-color:rgba(255,255,255,0.4); padding:5px 10px; margin:0 5px 5px 0; }
.project-item .project-top a.link-edit { width:25px; height:25px; border-radius:5px; background:white; display:flex; justify-content: center; align-items: center;}
.project-item .project-top a.link-edit img { width:15px; }
.project-item a.link-project { position:relative; display:inline-block; width:100%; color:white;  }
.project-item a.link-project hr { background:white; margin:20px 0; }
.project-item a.link-project .title { margin-bottom:0px; color:white; font-size:16px; line-height:$line-height; }
.project-item a.link-project:hover .title { text-decoration:underline; }
.project-item:hover { background-color: #048353; }


/* OTHER ITEM (commisions, junta, circulars, ...) */

.other-item { height:250px; background-color:white; border-radius: 10px;}
.other-item a { position:relative; display:flex; flex-direction: column; width:100%; height:100%; color:$color-txt; padding:20px 15px; overflow: hidden; }
.other-item a .tags { margin:0 0 8px; display:flex; flex-wrap:wrap;   }
.other-item a .tags span { display:inline-block; text-transform:uppercase; font-size:12px; line-height:$line-height2; border-radius:$border-radius; background-color:$color-bg; color:$color-txt; padding:5px 10px; margin:0 5px 5px 0; }
.other-item a .title { margin-bottom:10px; font-size:16px; line-height:$line-height; }
.other-item:hover a { background-color: #D7E1DD; border-radius: 10px;}




/* NEWS & AGENDA ARCHIVE */

/*.header-news { display:flex; align-items: flex-start; justify-content: space-between; }
.header-news .page-title { font-size:18px; font-family:$second; font-weight:$font-weight-normal; margin-bottom:0; }
.header-news .header-news-left hr { margin:20px 0 0; }
.header-news .header-news-right { display:flex; flex-wrap: wrap; justify-content: flex-end; }
.header-news .header-news-right .header-news-filter .filter-group button { padding: 8px 12px; border-radius:$border-radius; line-height:$line-height2; display: inline-block; margin-left: 5px; background-color:$color-bg; cursor: pointer;  }
.header-news .header-news-right .header-news-filter .filter-group button.active {background-color:#d2d2d2;}
.header-news .header-news-right .header-news-order select { width:auto; margin-left: 10px; padding:7px 40px 6px 10px; }
.header-news .header-news-right .header-news-search { margin-left: 10px; }*/

.container-news { display:flex; flex-wrap:wrap; position: relative; }
.container-news .news-item,
.container-news .agenda-item,
.container-news .file-item,
.container-news .project-item,
.container-news .other-item { width: 24%; margin: 0 1% 15px 0; position: relative; }


/*  NEWS SINGLE  */

.single-news-header { display:flex; margin-bottom:10px; background:white; border-radius:10px; overflow: hidden; min-height: 350px}
.single-news-header .single-news-header__left { flex-basis:50%; padding-top: 40px; padding-bottom: 30px; align-self: center;}
.single-news-header .single-news-header__left .tags { margin:0 0 5px; display:flex; flex-wrap:wrap;   }
.single-news-header .single-news-header__left .tags span { display:inline-block; text-transform:uppercase; font-size:12px; line-height:$line-height2; border-radius:$border-radius; background-color:$color-bg; padding:5px 10px; margin:0 5px 5px 0; }
.single-news-header.category-la-cambra .single-news-header__left .tags span { background-color:$color; color:white; }
.single-news-header.category-proyectos .single-news-header__left .tags span { background-color:$color_yellow; color:white; }
.single-news-header.category-socios .single-news-header__left .tags span { background-color:$color_blue; color:white; }
.single-news-header.category-socios .single-news-header__left .tags span { background-color:$color_blue; color:white; }
.single-news-header.category-otros-posts .single-news-header__left .tags span { background-color:$color_orange; color:white; }
.single-news-header .single-news-header__left .tags span.date { background-color:$color-bg; color:$color-txt; }
.single-news-header .single-news-header__left .title { font-size: 22px; margin-bottom:20px; }
.single-news-header .single-news-header__left p { line-height:$line-height3; }
.single-news-header .single-news-header__left hr { margin:10px 0 20px; }
.single-news-header .single-news-header__right { flex-basis:50%; background-size:cover; background-position:center; border-left:1px solid $color-bg;}

ul.event-list {  }
ul.event-list li { margin-bottom: 10px; display:flex; align-items:flex-start; }
ul.event-list li img { margin-right: 10px; min-width: 25px;}
ul.event-list li span { display: inline-block; margin-top: 5px;}

.single-news-content { background: white; border-radius: 10px; padding: 50px 10%;}
.single-news-content p { line-height: $line-height3; }
.single-news-content .tabla { margin: 40px 0;}
.single-news-content ul { padding-left: 25px; margin: 30px 0; }
.single-news-content ul li { list-style: disc; margin-bottom: 20px; line-height:$line-height3;}
.single-news-content h1,
.single-news-content h2,
.single-news-content h3,
.single-news-content h4 { margin:40px 0 30px; }
.single-news-content h1:first-child,
.single-news-content h2:first-child,
.single-news-content h3:first-child,
.single-news-content h4:first-child { margin:0 0 30px; }

.single-news-content img { margin:30px 0; width:100%; }
.single-news-content .wp-caption { max-width: 100%; background-color: white; border-radius: 10px; margin-top: 50px; margin-bottom: 50px; padding: 0; overflow: hidden;}
.single-news-content .wp-caption p.wp-caption-text {  text-align: left; line-height:120%; font-size:16px; padding:10px 20px; opacity:0.4; }

.single-news-content blockquote { margin: 50px 0; padding-left: 20px;  border-left: 1px solid $color; }

/* RELATED POST */

.single-news-related { margin-top: 10px; text-align: center;  }
.single-news-related .news-item { height: auto; padding:60px 0; border-radius:10px; }
.single-news-related .news-item a { display: inline-block; max-width:600px; }
.single-news-related .news-item a hr { margin:20px auto; background:white;  }
.single-news-related .news-item a .tags { display:flex; justify-content: center;}
.single-news-related .news-item a .tags span { display:inline-block; color:white; text-transform:uppercase; font-size:13px; line-height:$line-height2; border-radius:$border-radius; background-color:rgba(255,255,255,0.2); padding:5px 10px; margin:0 5px 5px 0; }
.single-news-related .news-item a .title { color:white; font-size:24px; margin:0 0 10px; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.single-news-related .news-item a .subtitle { color:white; }
.single-news-related .news-item a:hover .title { transform:translateY(-4px); }


@media only screen and (max-width: 1250px){
    .container-news .news-item,
    .container-news .agenda-item,
    .container-news .file-item,
    .container-news .project-item,
    .container-news .other-item { width: 32.33%; margin: 0 1% 15px 0; }
    .single-news-header .single-news-header__left .title { font-size: 20px;}
}
@media only screen and (max-width: 1100px){
	.header-news .header-news-right .header-news-filter .filter-group button,
	.header-news .header-news-right .header-news-order select,
	.header-news-search input[type=search] { font-size:13px; }
    .news-item a.link-news .title,
    .agenda-item .agenda-item-inner .agenda-top .title,
    .file-item a .title,
    .project-item a.link-project .title,
    .other-item a .title { font-size:15px; }
}
@media only screen and (max-width: 1000px){
	.header-news { flex-direction: column;}
	.header-news .header-news-right { margin-top: 20px; justify-content: flex-start;}
	.header-news .header-news-right .header-news-filter .filter-group button { margin-left:0; margin-right:5px; margin-bottom: 5px; }
	.header-news .header-news-right .header-news-order select { margin-left:0; margin-right: 10px;}
	.header-news .header-news-right .header-news-search { margin-left: 0; margin-right: 10px; }

    .container-news .news-item,
    .container-news .agenda-item,
    .container-news .file-item,
    .container-news .project-item,
    .container-news .other-item { width: 48%; margin: 0 1% 15px; }
    .news-item,
    .agenda-item,
    .file-item,
    .project-item,
    .other-item { height: 220px;}

    .single-news-header { flex-direction:column; }
    .single-news-header .single-news-header__right { flex-basis: unset; height: 300px;}
    .single-news-content { padding: 30px 5%;}
    .single-news-related .news-item { padding: 30px 0;}
    .single-news-related .news-item a .title { font-size: 20px;}
}
@media only screen and (max-width: 900px){
	.header-news-filter { width: 100%; margin-bottom: 10px;}

    .news-item a.link-news .title,
    .agenda-item .agenda-item-inner .agenda-top .title,
    .file-item a .title,
    .project-item a.link-project .title,
    .other-item a .title { font-size:14px; }

    .single-news-header .single-news-header__left .title { margin-bottom: 10px; font-size: 18px;}
    .single-news-related .news-item a .title { font-size: 18px;}
}
@media only screen and (max-width: 800px){
    .news-item,
    .agenda-item,
    .file-item,
    .project-item,
    .other-item { height: 200px;}

}
@media only screen and (max-width: 600px){

    .news-item a.link-news .title,
    .agenda-item .agenda-item-inner .agenda-top .title,
    .file-item a .title,
    .project-item a.link-project .title,
    .other-item a .title { font-size:13px; }
    .news-item,
    .agenda-item,
    .file-item,
    .project-item,
    .other-item { height: 180px;}

    .single-news-header .single-news-header__right { height: 250px;}
    .single-news-related .news-item { padding: 15px;}
    .single-news-related .news-item a .title { font-size: 16px;}
    .single-news-related .news-item a hr { margin: 10px auto;}
}
@media only screen and (max-width: 480px){
    .container-news .news-item,
    .container-news .agenda-item,
    .container-news .file-item,
    .container-news .project-item,
    .container-news .other-item { width: 100%; margin: 0 0 10px; }
    .news-item,
    .agenda-item,
    .file-item,
    .project-item,
    .other-item { height: auto;}

    .single-news-header .single-news-header__right { height: 200px;}
    .single-news-related .news-item a .title { font-size: 14px;}

    .header-news .header-news-right { width:100%;}
    .header-news .header-news-right .header-news-search { width:100%; margin-right:0;}
    .header-news-order.sort-options { width: 100%; margin-bottom: 10px;}
    .header-news .header-news-right .header-news-order select { width:100%; margin-right:0; }

}
