/* ------------------------------------------------------------------------- */


/* NEWSLETTER FORM STYLES */


/* ------------------------------------------------------------------------- */

.hundreds__newsletter-form { margin-top:20px; }
.hundreds__newsletter-form input[type=email] { margin-bottom:0; width:auto; border-radius:5px 0 0 5px; flex: 1; }
.hundreds__newsletter-form .hundreds-form__form-box-container { display: flex; align-items: flex-start;}
.hundreds__newsletter-form .submit-button-container { position: relative; margin-top:0; max-width: 430px;}
.hundreds__newsletter-form .submit-button-container input[type=submit] { border-radius:0 5px 5px 0; width:100px; }
.hundreds__newsletter-form .hundreds-form__checkbox label { font-size:12px; color:white; }
.hundreds__newsletter-form .hundreds-form__checkbox label a { border-color:white; color:white; }
.hundreds__newsletter-form .hundreds-form__checkbox label:before { top:-2px; border-color:white; }
.hundreds__newsletter-form .hundreds-form__checkbox label:after { top:1px; background-color:transparent; }
.hundreds__newsletter-form .hundreds-form__checkbox input[type=checkbox]:checked + label {color:white;} 
.hundreds__newsletter-form .hundreds-form__checkbox input[type=checkbox]:checked + label:after {background-color:white;}

@media only screen and (max-width: 800px){
	.hundreds__newsletter-form .submit-button-container input[type=submit] { padding:10px; }
}