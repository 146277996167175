/* ------------------------------------------------------------------------- */


/* PAGE PAGE STYLES */


/* ------------------------------------------------------------------------- */


/*PERFIL EMPRESA*/
.user_statistics{ display: flex}
.user_statistic__card{ padding: 20px; background-color: white; border-radius: 10px;flex-basis: 20%;margin-right: 15px; display: flex; flex-direction: column; justify-content: center; text-align: center;}
.user_statistic__card span{ display:inline-block; font-weight: $font-weight-bold; font-size:40px; line-height:100%; margin-bottom:5px;}
.user_statistic__card h2 { margin-bottom: 10px;}
.user_statistic__card p { font-size: 18px; line-height: 110%; margin-bottom: 0;}
.user_statistic__card:last-child{ margin-right: 0px; text-align: left;}

.user_statistic__card_number { font-size: 25px}
.member-section {display: flex;}
.member-main_section {flex-basis: 50%;padding: 0px 15px 0px 0px;}
.member-side_section {flex-basis: 50%;}
.form_group h2 { margin-bottom: 20px}
.social_networks{display: flex; align-items: center; margin-bottom: 10px}
.social_networks img{width: 30px; margin-right: 15px;}
.social_networks p{margin: 0}
#addOtherAddress{ margin: 0}
.permission-section {display: flex; flex-wrap: wrap;}
.permission-left_section {flex-basis: 49%;padding: 0px 15px 0px 0px; margin-right: 15px;}
.permission-right_section {flex-basis: 49%;}
.label-noform { font-weight: $font-weight-bold; font-size: 13px; display: inline-block; margin-bottom: 2px;}
.p-noform { font-size:17px; margin-bottom:15px;}
.event-image_content { margin-bottom: 15px;}

/*DATATABLES*/

.dataTables_wrapper {margin-top: 30px; margin-bottom: 30px;}
.dataTables_wrapper.no-footer .dataTables_scrollBody {border-bottom: none;}

.dataTables_length { float: left;}
.dataTables_length label{ display: flex; margin-top: 10px; margin-bottom: 10px;}
.dataTables_length select{ padding:3px 20px 3px 6px !important; border-radius: 5px!important ; color: #000000!important; margin:0 10px; }

.dataTables_filter { float: right; text-align: right;}
.dataTables_filter input { padding:7px 30px 7px 10px !important; border-radius: 5px !important; background-color: #fff !important; border: none !important; }

.dataTables_paginate{ margin-top: 10px; text-align: right;}
.paginate_button {box-sizing: border-box; color: $color-txt !important; display: inline-block; padding: 0.5em 1em;  text-align: center; text-decoration: none !important;cursor: pointer; color: #333 !important;border: none !important; border-radius: 5px !important; box-shadow: none !important;}
.paginate_button:hover { background: white !important; }
.paginate_button.current { color: white!important; background: $color !important; }
.paginate_button.disabled,
.paginate_button.disabled:hover, 
.paginate_button.disabled:active { opacity:0.6; cursor: default; background:none !important; color:$color-txt !important; }
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {color: white!important;}

.table { background:white; border-radius:10px; }
.table thead{ background-color: #ccdbd6};
.table { font-size: 14px}
.table th,
.table td { font-size: 15px; padding: 8px 15px!important; line-height: 120% !important; border-top:1px solid #f0f0f0!important; }
.table th { position:relative; border:none!important; font-weight:$font-weight-bold; vertical-align: middle !important;height: 50px !important;}
.dataTables_scrollBody .table th { height: 0px !important; padding: 0 !important;}
.row{margin-top: 30px}


table.dataTable.no-footer{ border-bottom: none!important;}
table.dataTable thead .sorting {background-image: url(../images/sort_both.svg) !important; background-position: calc(100% - 5px) center !important; background-repeat: no-repeat;}
table.dataTable thead .sorting_desc {background-image: url(../images/sort_desc.svg) !important; background-position: calc(100% - 5px) center !important; background-repeat: no-repeat;}
table.dataTable thead .sorting_asc {background-image: url(../images/sort_asc.svg) !important; background-position: calc(100% - 5px) center !important; background-repeat: no-repeat;}

.table_logo{width: 50px;height: 50px;object-fit: cover;outline: 6px solid #f5f5f5;}
#sent-table_filter{ display: none;}

thead th:first-child {border-left: none;border-top-left-radius: 5px;}
thead th:last-child {border-left: none;border-top-right-radius: 5px;}
td a:hover{ color: $color}
.delete_btn{ color: #d90a19!important;}

table.dataTable.display tbody tr > .sorting_1, table.dataTable.order-column.stripe tbody tr > .sorting_1 {background-color: #ffffff!important; }
table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {background-color: #D7D7D7!important; }
table.dataTable tbody tr {background-color: #ffffff!important;}
.table-hover tbody tr:hover {background-color: #D7D7D7!important;}
.table_link{ color: #006740}
.table_btn img {width: 16px;}
.table_btn{margin-right: 15px}
.table_buttons{ display: flex; width:58px;}
table.sortable th:not(.sorttable_sorted):not(.sorttable_sorted_reverse):not(.sorttable_nosort):after { content: ""; position: absolute; width: 6px; height: 9px; background: url(../images/sort_both.svg); right: 5px; top: calc(50% - 5px);}

.table_buttons a .table_save { cursor: pointer; display: inline-block; min-width: 15px;}
.table_buttons a span.warning { display: flex; justify-content: center; align-items: center; width: 20px; height: 20px; border-radius: 100px; background: $color-notification; color: white; font-weight: $font-weight-bold; font-size: 14px; margin-left: 10px; }



/*PERFIL USUARI*/
.tab {overflow: hidden;}
.tab button {background-color: inherit;float: left;border: none;outline: none;cursor: pointer;padding: 7px 16px;transition: 0.3s;font-size: 17px;}
.tab button:hover {border-radius: 5px 5px 0px 0px; background-color: #ddd;}
.tab button.active {border-radius: 5px 5px 0px 0px;border: 1px solid green;border-bottom: none;background: #f3f4f6;}
.tabcontent {display: none;padding: 25px 0px;border-top: 1px solid $color; margin-top: -1px;}
.tablinks{ color: $color}



@media only screen and (max-width: 1250px){
    .user_statistic__card h2 { font-size: 17px;}
    .user_statistic__card p { font-size: 17px; }
    .table th,.table td { font-size: 14px;}
}
@media only screen and (max-width: 1100px){
    .user_statistic__card{ padding: 15px; margin-right: 10px; }
    .user_statistic__card span {  font-size:35px; }
    .user_statistic__card h2 { font-size: 16px;}
    .user_statistic__card p { font-size: 16px; }
}
@media only screen and (max-width: 1000px){
    .user_statistic__card span {  font-size:30px; }
    .user_statistic__card h2 { font-size: 14px;}
    .user_statistic__card p { font-size: 14px; }
    .p-noform { font-size: 16px; margin-bottom: 15px;}
    .tab button { font-size:16px; }
    .table th,.table td { font-size: 13px;}
}
@media only screen and (max-width: 900px){
    .tab button { font-size:15px; }
}
@media only screen and (max-width: 800px){
    .user_statistic__card span {  font-size:25px; }
    .user_statistic__card h2 { font-size: 13px;}
    .user_statistic__card p { font-size: 13px; }
    .p-noform { font-size: 15px; margin-bottom: 12px;}
}
@media only screen and (max-width: 640px){
    .dataTables_wrapper .dataTables_length { float:left!important; }
    .dataTables_wrapper .dataTables_filter { float:right!important; }
}
@media only screen and (max-width: 600px){
    .user_statistics { flex-wrap: wrap;}
    .user_statistic__card { flex-basis:auto; margin-bottom:10px;}
    .p-noform { font-size: 14px; margin-bottom: 10px;}
    .member-section { flex-direction: column;}
    .member-main_section { padding: 0;}
    .tab button { font-size:15px; padding: 7px 10px; }
}   
@media only screen and (max-width: 480px){
    .tab {display: flex; flex-direction: column;}
    .tabcontent {border:none;}
    .tab button { border-bottom:1px solid $color; }
    .tab button.active { background:$color; color:white; border-radius:0; border:none; border-bottom:1px solid $color; }
    .dataTables_wrapper .dataTables_filter {width:100%;}
    .dataTables_wrapper .dataTables_filter label {width:100%;}
    .dataTables_wrapper .dataTables_filter input { width: 100%; margin:0 0 10px 0;}
}   


