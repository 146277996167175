/* ------------------------------------------------------------------------- */


/* NOUISLIDER STYLES */


/* ------------------------------------------------------------------------- */

.noUi-target { background: #FAFAFA!important; border-radius: 0px!important;;}
.noUi-connect { background: #ccdbd6!important;;}
.noUi-horizontal .noUi-handle { width: 10px!important;; height: 18px!important;; right: -5px!important;; top: -1px!important;;}
.noUi-handle { border: 1px solid $color!important;; border-radius: 0px!important;; background: $color!important;; box-shadow:none!important;;}
.noUi-handle:before, .noUi-handle:after { display:none!important;; }
.price-slider-label{ width: 100%}
