/* ------------------------------------------------------------------------- */


/* PAGE CONTENT FORM STYLES */


/* ------------------------------------------------------------------------- */

/*Alerts*/
.message-hidden {display: none;}
.alert-success {color: #155724;background-color: #d4edda;border-color: #c3e6cb;}
.alert {position: relative;padding: 0.75rem 1.25rem;margin-bottom: 1rem;border: 1px solid transparent;border-radius: 0.25rem;}
.alert-danger {color: #721c24;background-color: #f8d7da;border-color: #f5c6cb;}
button.close {display: none;}
#error_msg {margin: 0;}

/*Lang button*/
.button_lang{cursor: pointer;border: none;color: #8a8a8a !important;width: auto !important;padding: 3px 8px !important;margin-left: 10px !important;
    background: #f5f5f5 !important;display: inline-block;font-size: 13px;font-weight: 400;border-radius: 5px;transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);}
.lang_selected {color: #000000 !important;border: 1px solid #d4d3d3 !important;cursor: auto;}

/*Form*/
.post-main_section {flex-basis: 70%;padding: 0px 20px 0px 0px;}
.post-side_section {flex-basis: 30%;}
.thumbnail_content{ background-color: $color-input; border: 1px solid $color-dark_grey;border-radius: 5px;position: relative;}
.thumbnail_button{position: absolute;top: 50%;transform: translateY(-50%);width: 100%;text-align: center;cursor: pointer;}
.thumbnail_button span{ background-color: #e5e8e9bd; padding: 15px 20px;border-radius: 6px;}
.delete_btn {color: #d90a19 !important;}
.status{ font-weight: $font-weight-bold; color:$color; font-size: 14px; margin-bottom: 30px; margin-top: -20px;}
.post_thumbnail {height: 165px;margin: auto;}

/*Media popup*/
.media-section-background{display: none;left: 0;top: 0;width: 100%;height: 100%;position: fixed;z-index: 1000;background-color: rgba(0, 0, 0, 0.7);}
.media-section-container {position: relative;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;}
.media-close-section {position: absolute;width: 100%;height: 100%;left: 0;top: 0;z-index: 1;}
.media-info-container {background-color: #fff;margin: auto;width: 90%;max-height: 100%;max-width: 1000px;z-index: 1;overflow: auto;}
.media-header {padding: 30px 0;}
.media-header p {text-align: left;margin: auto 0;margin-left: 30px;font-weight: $font-weight-bold;line-height: 120%;font-size: 24px;}
.nav-tabs .nav-link {border: 1px solid transparent;border-top-left-radius: 0.25rem;border-top-right-radius: 0.25rem;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {color: $color!important; background-color: #fff; border-color: #dee2e6 #dee2e6 #fff;}
.tab-regular .tab-content {background-color: #fff;padding: 30px;border: 1px solid #e6e6f2;border-radius: 4px;border-top-left-radius: 0px;}
.modal-scroll {overflow-y: scroll;height: 50vh;text-align: left!important;}
.media-img {height: 150px;margin-top: 5px;object-fit: cover;display: inline;width: 150px;}
.img-active {border: 4px solid $color;}
.dropzone{ border: none!important;}
.modal-body{min-height: 300px!important;}
#uploadMedia .modal-body{ text-align: center;}
.nav-tabs {border-bottom: 1px solid #dee2e6;}
.nav-fill .nav-item {flex: 1 1 auto;text-align: center;}
.fade.show {opacity: 1;}
.modal-backdrop.show { opacity: .5;}
.large-input{border-radius: 0px; margin: 0px}
.dz-message {text-align: center;padding: 1.5rem;display: block;font-size: .875rem;font-weight: 500;opacity: 1;cursor: pointer;border-radius: 0.5rem;background: #fff;
    border: 1px dashed #E9EEF2;transition: all .35s ease;}
.dropzone {min-height: 150px;border: 1px solid $color!important;background: white;padding: 20px 20px;}
.dropzone-title{ margin-bottom: 20px; }
.excel-loader{width: 30px;margin: auto;}
.section-loader{display: flex; width: 100%; flex-direction: column; margin-top: 20px;}
.section-loader p{text-align: center;margin-bottom: 10px;margin-top: 20px;}
.dropzone-no-borders{ border: none!important;}



/*Modal*/
.modal-footer{justify-content: center; border-top: none; padding-bottom: 30px; display: flex}
.modal-title{ font-family: $main; font-weight: $font-weight-bold; font-size: 17px; width: 100%}
.modal-download{ text-align: left;}
.modal-download label{font-family: $main; font-size: 15px}
.modal-content{ border: none; border-radius: 10px;}
.note-modal .modal-dialog{ box-shadow: none;}
.modal-newsletter{ padding: 0px 30px;margin-top: -25px;}
.modal .note-modal .in{background-color: rgba(0, 0, 0, 0.4);}
button.close{display: none}
.modal-preheader{ padding: 10px 20px 10px 30px;  justify-content: left; border: none; text-align: center}
.modal-header{ justify-content: left; padding: 10px 30px 30px 30px; border: none; text-align: center}
.modal-dialog {max-width: 700px; margin: 30px auto; height: calc(100vh - 60px); display: flex; align-items: center;}
div.form-group.note-group-image-url{ margin-top: 30px;}
div.form-group.note-form-group.note-group-select-from-files{ margin-top: 10px}
.btn-primary{font-family: $main; color: white!important; display: inline-block; background: $color; font-size: 14px; font-weight: $font-weight-bold; cursor: pointer;
    border-radius: 5px; border: 1px solid transparent; padding: 15px 25px; width: 170px; -webkit-appearance: none; transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95); }
.btn-primary:hover { background: #00C97D; color: white!important; border-color: #00C97D; font-weight: $font-weight-bold;}
.btn-primary:disabled { background: #068052; color: white!important; border-color: #068052; font-weight: $font-weight-bold;}
.btn-primary[disabled]:hover { background: #068052; color: white!important; border-color: #068052; font-weight: $font-weight-bold;}
.modal_buttons{display: flex; margin-top: 40px;}
.modal_buttons a{flex-basis: 50%; padding: 60px 20px;border: 1px solid $color;border-radius: 10px;margin: 0px 50px;}
.modal_buttons a:hover{background-color: $color; color: white!important;}
.close_modal {float: right;height: 60px;border-radius: 100px;background-color: white;top: 25%;right: 10%;cursor: pointer;opacity: .2;}
.close_modal span {width: 22px; height: 22px; background: url(../images/close.svg) 50%/cover; display: block;}

/* DASHBOARD MODAL */
.modal_dashboard .modal-dialog { max-width: 1000px; }
.modal_dashboard .modal-dialog .modal-custom { text-align: left; padding: 0 5% 20px;}
.modal_dashboard .modal-dialog .modal-custom .tags { margin:0 0 15px; display:flex; flex-wrap:wrap;   }
.modal_dashboard .modal-dialog .modal-custom .tags span { display:inline-block; font-size:14px; line-height:$line-height2; border-radius:$border-radius; background-color:$color-bg; color:$color-txt; padding:5px 10px; margin:0 5px 5px 0; }
.modal_dashboard .modal-dialog .modal-custom .title { margin-bottom:20px; line-height:$line-height; }
.modal_dashboard .modal-dialog .modal-custom .announce-container { padding-left:20px; border-left:1px solid $color; margin-bottom:30px;}
.modal_dashboard .modal-dialog .modal-custom .announce-container .announce-item { margin-bottom:15px;}
.modal_dashboard .modal-dialog .modal-custom .announce-container .announce-item h2 { font-size:14px; margin-bottom:5px;}
.modal_dashboard .modal-dialog .modal-custom .announce-container .announce-item p { font-size:14px; margin-bottom:5px;}
.modal_dashboard .modal-dialog .modal-custom .announce-container .announce-item:last-child { margin-bottom:0;}
.modal_dashboard .modal-dialog .modal-custom .updates { margin-bottom:20px; }
.modal_dashboard .modal-dialog .modal-custom .updates .title { color:$color-notification; margin-bottom:8px; }
.modal_dashboard .modal-dialog .modal-custom .updates .updates-box { padding:15px 25px; border-radius:$border-radius; background:#FFE1DD; }
.modal_dashboard .modal-dialog .modal-custom .updates .updates-box p {font-size:14px; margin-bottom:6px;}



@media only screen and (max-width: 1250px){
    .post-main_section { flex-basis: 60%;}
    .post-side_section { flex-basis: 40%;}
}
@media only screen and (max-width: 1000px){
    .post-main_section { flex-basis: 52%;}
    .post-side_section { flex-basis: 48%;}
}
@media only screen and (max-width: 600px){
    .post-main_section { width: 100%; padding:0;}
    .post-side_section { width: 100%;}
}

