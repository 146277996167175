/* ------------------------------------------------------------------------- */


/* INSTAGRAM STYLES */


/* ------------------------------------------------------------------------- */

.hundreds__instagram a{
	width: 25%;
    display: inline-block;
    height: 200px;
}

.hundreds__instagram a li{
	width: 100%;
    height: 200px;
    background-size: cover;
}