/* ------------------------------------------------------------------------- */


/* HOME CSS */


/* ------------------------------------------------------------------------- */


/* ALERT BOX */
.alert-box { display:flex; padding:15px 25px; border-radius:$border-radius; background:#FFE1DD; margin-bottom:30px; }
.alert-box .alert-left { margin-right:20px;}
.alert-box .alert-right h2 { margin-bottom:5px; font-size:16px; line-height:110%;  }
.alert-box .alert-right p { margin-bottom:5px; font-size:14px; line-height:110%; }

/* DASHBOARD */
.dashboard-section { padding:30px 0; }
.dashboard-section .dashboard-title { font-size:24px; margin-bottom:25px; }
.dashboard-section .dashboard-title hr { background:$color; border:none; width:50px; margin:15px 0; }





@media only screen and (max-width: 1100px){
	.alert-box { margin-bottom:20px;}
	.alert-box .alert-right h2 { font-size: 15px;}
	.alert-box .alert-right p { font-size: 13px;}
	.dashboard-section { padding: 20px 0;}
	.dashboard-section .dashboard-title { font-size: 20px; margin-bottom: 15px;}
}
@media only screen and (max-width: 900px){
	.dashboard-section .dashboard-title { font-size: 18px;}
}
@media only screen and (max-width: 800px){
	.alert-box { margin-bottom:10px;}
}

