/* ------------------------------------------------------------------------- */


/* HEADER STYLES */


/* ------------------------------------------------------------------------- */

.header { padding: 10px 0px;position: fixed; top: 0;right: 0;left: 0;z-index: 15; box-shadow: rgb(149 157 165 / 20%) 0px 3px 10px;}
.menu_logo svg { width: 100px!important;}
.main_header{padding: 0 40px;}
.menu_title{ color: $color; font-weight: $font-weight-bold; font-family: $main; margin: 30px;border-left: 1px solid $color-bg;padding: 15px; font-size: 20px; }
.menu_user { display: flex}
.menu_user span { color: $color-txt; padding: 6px 20px;  font-weight: $font-weight-bold; font-family: $main}
.menu_user_logout { width: 35px;}
.header_logo{ width: 100px }

.nav-link{ display: flex; justify-content: space-between; color: $color-txt!important; }
.nav-link-child{font-weight: $font-weight-normal!important; padding-left: 20px!important; font-size: 14px; background-color:#ebf3f0}
.nav-link-child:hover{ background-color: #ccd8d5!important; font-weight: $font-weight-normal!important; color: $color-txt!important;}
.nav-link svg { margin-top: 5px!important; width: 16px;}
.nav-link-active svg g{ fill: white }
.nav-link[aria-expanded="true"]{background-color: $color; color:white!important;}
.nav-link[aria-expanded="true"] svg g{fill: white}
.nav-left-sidebar {position: fixed; /*width: 230px;*/ top: 84px;background-color: #fff;font-family: $main; overflow-y: auto;height: 100%;}
.nav-left-sidebar .navbar {position: relative;padding: 10px 0px;}
.navbar-collapse {flex-basis: 100%;flex-grow: 1;align-items: center;}
.navbar-nav {display: flex;flex-direction: column;padding-left: 0;margin-bottom: 0;list-style: none;}
.navbar-nav {display: block;margin-right: 30px;}
.nav-left-sidebar .navbar-nav {width: 100%;}
.nav-left-sidebar .navbar-nav .nav-link {font-size: 16px; font-weight: $font-weight-bold; padding: 7px 25px; color: #71789e; transition: 0.3s;}
.nav-left-sidebar .navbar-nav .nav-link {color: $color-txt;}
.nav-left-sidebar .navbar-nav .nav-link-active {color: white!important;background: $color;}
.nav-title{margin: 0;font-weight: $font-weight-normal;padding: 8px 30px 5px 25px;color: $color-txt!important; font-size: 16px;margin-top: 20px; font-family: $second; }
hr{border: none;margin: 5px 28px 10px 25px;width: 50px;height: 1px;background: $color;}
.align-hr{ margin-left: 0}
.responsive_menu{position: fixed;width: 100%;background-color: #fff;overflow-y: scroll;height: 100%;padding-bottom: 75px;}
.nav-link-active_responsive{color: white !important; background: $color;}
.nav-link-active_responsive:hover{ color: white!important;}
.bt-notification { font-family:$main; background-color:#f5f5f5;margin: auto;max-width: 170px;font-size: 14px!important;text-align: center;font-weight: $font-weight-normal!important; border-radius: 5px;line-height: 100%;margin-top: 15px;}

/*Breadcrumbs*/
.breadcrumbs{margin: 20px 20px 0px 230px; padding: 0 30px;}
.breadcrumb { background-color: transparent; padding: 0; margin-bottom: 0;}
.breadcrumb-item+.breadcrumb-item::before {content: "|"!important; color: $color}
.breadcrumb-link{ font-weight: $font-weight-normal!important; font-family: $second;  font-size: 13px}
.breadcrumb-item{ font-family: $second; font-size: 13px; font-weight: $font-weight-bold!important; color: $color-txt!important;}

.notification{ color: white; background-color: $color-notification; border-radius: 25px; padding: 0px 8px; font-size:12px; font-weight: $font-weight-normal; margin-right: 10px }
.nav-link_icon{ display: flex}
.notification_blob{position: absolute;right: -15px; padding: 2px 11px; font-weight: $font-weight-bold; z-index: 10}

@media only screen and (max-width: 1100px){
  .nav-left-sidebar .navbar-nav .nav-link { font-size: 14px; padding: 5px 20px;}
  .nav-title { padding: 8px 20px 5px 20px; margin-top:10px; font-size: 14px;}
  hr { margin: 5px 20px 5px 20px;}
  .nav-link svg { display: none;}
  .menu_title {padding: 5px 15px; font-size: 17px; margin: 0 30px;}
  .main_header { padding: 0 20px;}
  .main_header .h-16 {height:auto !important;}
  .header {height:60px;}
  .header_logo { width: 70px;}
  .nav-left-sidebar {top: 54px;}
  .breadcrumbs { margin: 20px 20px 0px 180px; padding: 0 3%;}
}
@media only screen and (max-width: 800px){
  .nav-left-sidebar { display:none; }
  .breadcrumbs { margin: 20px 0 0;}
}
@media (min-width: 800px) {
  .toggle {display: block!important;}
  #toggle-hamburger {display: none!important;}
}

@media (max-width: 800px) {
  #toggle-hamburger {display: block !important;}
  #user-info{display: none;}
}
